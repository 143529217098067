export default {
  proxy_path: "https://8ucsq2rr73.execute-api.us-west-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://wjxruvq8p6.execute-api.us-west-2.amazonaws.com/api",
  google_api_key: "AIzaSyDVZjTV9W6b88zZ1cSax2y9SVBUckHtjCo",
  main_company_term_id: "6",
  crm_base_url: "testing.saas.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.testing.saas.forwoodsafety.com",
  MAP_REPORT_ACCESS_ROLE: "MapReportAccess",
  forwoodId: {
    URL: "https://id.testing.saas.forwoodsafety.com",
    APP_CLIENT_ID: "4kh7ssi3cmqs3s3na5nfal025c",
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://jcds2pjb7d.execute-api.us-west-2.amazonaws.com/prod",
  },
  reactApp: {
    HOSTNAME: "https://mapreport.testing.saas.forwoodsafety.com",
  },
  get forwoodIdAuthUrl() {
    return `${
      this.forwoodId.URL
    }/authorize?response_type=token&scope=openid&client_id=${
      this.forwoodId.APP_CLIENT_ID
    }&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  },
};
